import { WebStorageStateStore } from "oidc-client-ts";
import { unref } from "vue";
import type { VueOidcSettings } from "vue3-oidc";
import { createOidc, useAuth, useOidcStore } from "vue3-oidc";
import { vueAppAuthority, vueAppClientId } from "@/extensions/extra";

const { state } = useOidcStore();
const { setRedirectUri } = useAuth();
const oidcSettings: VueOidcSettings = {
  authority: vueAppAuthority,
  scope:
    "openid profile offline_access IdentityServerApi VaultNUIAPI.all DashboardAPI.all",
  client_id: vueAppClientId,
  redirect_uri: location.origin + process.env.VUE_APP_REDIRECT_URI,
  post_logout_redirect_uri: location.origin,
  response_type: "code",
  loadUserInfo: true,
  userStore: new WebStorageStateStore({
    prefix: "vue3-oidc",
    store: window.sessionStorage,
  }),
  automaticSilentRenew: true,
  monitorSession: true,
  silent_redirect_uri: location.origin + "/silent-renew.html",
  onSigninRedirectCallback(user) {
    location.href = unref(state).redirect_uri || "/";
  },
  onBeforeSigninRedirectCallback() {
    setRedirectUri(location.pathname + location.search);
  },
};

function runAuth() {
  createOidc({
    oidcSettings: oidcSettings,
    auth: false,
    refreshToken: {
      enable: true,
      time: 10 * 1000,
    },
    events: {
      addUserLoaded: (user) => { console.log(user); },
      addUserSignedOut: () => { },
    },
    redirectUriKey: location.origin + process.env.VUE_APP_REDIRECT_URI,
  });
}

runAuth();
