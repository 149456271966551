import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-h5"
}
const _hoisted_2 = {
  key: 1,
  class: "drawing",
  id: "loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SignalR = _resolveComponent("SignalR")!
  const _component_MediaDeviceNotSupported = _resolveComponent("MediaDeviceNotSupported")!
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_Drawer = _resolveComponent("Drawer")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_q_layout = _resolveComponent("q-layout")!
  const _component_q_linear_progress = _resolveComponent("q-linear-progress")!
  const _component_HandleCache = _resolveComponent("HandleCache")!

  return (_openBlock(), _createBlock(_component_HandleCache, { ref: "cacheBuster" }, {
    default: _withCtx(({ isLoading, isLatestVersionAvailable }) => [
      isLoading
        ? (_openBlock(), _createElementBlock("h1", _hoisted_1, "Loading..."))
        : isLatestVersionAvailable
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_ctx.isAuthenticated)
                ? (_openBlock(), _createBlock(_component_SignalR, { key: 0 }))
                : _createCommentVNode("", true),
              _createVNode(_component_MediaDeviceNotSupported),
              _createVNode(_component_q_layout, null, {
                default: _withCtx(() => [
                  (_ctx.isAuthenticated)
                    ? (_openBlock(), _createBlock(_component_HeaderComponent, { key: 0 }))
                    : _createCommentVNode("", true),
                  (_ctx.isAuthenticated)
                    ? (_openBlock(), _createBlock(_component_Drawer, { key: 1 }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_q_page_container, null, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_component_router_view, {
                        key: _ctx.$route.fullPath
                      }))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.loadingSpinner)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_q_linear_progress, {
                      dark: "",
                      rounded: "",
                      indeterminate: "",
                      color: "warning"
                    })
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _createTextVNode(_toDisplayString(_ctx.cacheBuster.clearCacheAndReload()), 1)
            ], 64))
    ]),
    _: 1
  }, 512))
}