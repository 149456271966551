import { computed, defineComponent, onMounted, ref } from "vue";
import store from "@/store";
import Drawer from "@/components/drawer/Drawer.vue";
import HeaderComponent from "@/components/header/Header.vue";
import SignalR from "@/components/signalR/SignalR.vue";
import MediaDeviceNotSupported from "@/components/media-device-not-supported/MediaDeviceNotSupported.vue";
import { faviconSrcUrl } from "@/extensions/extra";
import HandleCache from '../../HandleCache.vue';

const cacheBuster = ref();

export default defineComponent({
  name: "App",
  components: {
    Drawer,
    HeaderComponent,
    MediaDeviceNotSupported,
    SignalR,
    HandleCache
  },
  setup() {
    const isLoggedIn = computed(
      () => store.state.isLoggedIn,
    );
    const loadingSpinner = computed(
      () => store.state.loadingSpinner,
    );
    const isAuthenticated = computed(
      () => store.state.isAuthenticated,
    );

    onMounted(() => {
      changeFavicon();
    });

    function changeFavicon() {
      let favicon;
      favicon = "";
      favicon = document.createElement('link');
      favicon.type = 'image/png';
      favicon.rel = 'icon';
      favicon.href = faviconSrcUrl;
      document.getElementsByTagName('head')[0].appendChild(favicon);
    }

    return {
      changeFavicon,
      isLoggedIn,
      loadingSpinner,
      isAuthenticated,
      cacheBuster
    };
  },
});
